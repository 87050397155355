import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We started PayPerks, six years ago now, based on the insight that sweepstakes-based rewards resonated with underserved consumers and behaviors could be nudged by associating a chance to win a cash prize with desired behavior-improving actions. And given that education is often a key component of behavior change, we knew an important action to incentivize was simply learning about how to change one’s behavior.`}</p>
    <p>{`While our model had many applications, we decided to start with changing financial behaviors as there were positive benefits to improving financial behaviors for both consumers and the financial institutions serving them. We saw that prepaid debit cards were becoming the main financial product utilized by underserved consumers and so we designed the PayPerks user experience to be integrated into the card lifecycle: you would learn about PayPerks when you get your card, your educational curriculum would be keyed off what card you have, you would earn PayPerks points based on how you used your card, and, when you won a prize, it would be loaded back to your card. While v1 of this product wasn’t perfect, it turned out a lot of our hypotheses were true and that this model was a pretty efficient, scalable and measurable way of nudging financial behaviors.`}</p>
    <p>{`Fast forward to 2015, PayPerks has been integrated with some of the largest banks and government programs for the purposes of improving the financial capabilities of their most vulnerable constituents. Hundreds of thousands of benefit recipients have signed up for PayPerks and shown measurable improvements in their financial capabilities. More importantly, we are learning a lot as we go. And with each passing month, we get better and more efficient at what we do. With each milestone hit, we get more ambitious about the applications for our model and technology. If PayPerks can effectively nudge financial behaviors, what’s to say it won’t work to nudge other critical life skills?`}</p>
    <p>{`Stay tuned for the next post where I’ll talk about the new vertical we’re going to tackle soon.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      